<template>
    <section class="start-free-area mb-5">
        <div class="container">
            <div class="col-12">
                <!-- Start Free Content -->
                <div class="start-free-content d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between shadow-lg" data-aos="fade-up">
                    <!-- Start Free Content -->
                    <div class="start-free-text">
                        <h2 class="mb-2">Interested in collaborating?</h2>
                        <span>We can leverage our unique skillset to launch that next big idea.</span>
                    </div>
                    <!-- Start Free Button -->
                    <div class="start-free-btn mt-4 mt-lg-0">
                       <a href="mailto:info@insuranceportal.co.za?subject=Lets collaborate" class="btn btn-bordered"><span>Let's meetup</span></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>