<template>
    <section class="contact-area bg-gray ptb_100">
        <!-- Shapes Container -->
        <div class="shapes-container d-none d-sm-block">
            <div class="shape-2"></div>
            <div class="shape-3"></div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8">
                    <!-- Download Text -->
                    <div class="download-text text-center">
                        <h2>About us</h2>
                        <p class="my-3">Based in Somerset West, we are a boutique actuarial consultancy and software provider focused on the financial services industry.  We strive for long lasting relationships with a core set of clients based on trusting and mutually benefitial partnerships.
                            
                            <!--We seek out interesting and unqiue problems to solve and prefer annuity income over up front costs, ensuring our interests are aligned and fees are earned as value is added.--></p>
                        <!--p class="d-none d-sm-block my-3">Lorem </p-->
                        <!-- Store Buttons -->
                        <!--div class="button-group store-buttons">
                            <a href="#" class="btn btn-bordered">
                                <i class="icofont icofont-brand-android-robot dsp-tc"></i>
                                <p class="dsp-tc">GET IT ON
                                    <br> <span>Google Play</span></p>
                            </a>
                            <a href="#" class="btn btn-bordered">
                                <i class="icofont icofont-brand-apple dsp-tc"></i>
                                <p class="dsp-tc">AVAILABLE ON
                                    <br> <span>Apple Store</span></p>
                            </a>
                            <a href="#" class="btn btn-bordered d-none d-lg-inline-block">
                                <i class="icofont-brand-windows dsp-tc"></i>
                                <p class="dsp-tc">AVAILABLE ON
                                    <br> <span>Windows Store</span></p>
                            </a>
                        </div-->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>