<template>
    <section id="Automation" class="section about-app-area ptb_50">
        <!--div class="shapes-container">
            <div class="shape bg-shape"></div>
        </div-->
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- About Thumb -->
                    <div class="about-thumb text-center d-none d-lg-block">
                        <img src="assets/img/automation.png" alt="">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- About Text -->
                    <div class="about-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-4">
                            <span class="text-uppercase d-none d-sm-block">Automation</span>
                            <h2 class="text-capitalize">Process automation and data integration</h2>
                        </div>
                        <p class="my-3">Team members and staff repeating the same mundane tasks each month?  Get them off the hamster wheel by automating the tasks while they spend their time analysing and improving the business.</p>
                        <p class="d-none d-sm-block my-3">From <b>simple spreadsheets</b> to <b>enterprise integration, reconcilliation and automation</b>, we can help to optimise your business processes.</p>
                        <!-- Store Buttons -->
                        <div class="button-group store-buttons">
                            <a href="mailto:info@insuranceportal.co.za?subject=Automation" class="btn btn-bordered">
                                <i class="icofont icofont-gears dsp-tc"></i>
                                <p class="dsp-tc">HAVE A QUESTION?
                                    <br> <span>Mail us today</span></p>
                            </a>
                            <!--a href="#" class="btn btn-bordered">
                                <i class="icofont icofont-brand-apple dsp-tc"></i>
                                <p class="dsp-tc">AVAILABLE ON
                                    <br> <span>Apple Store</span></p>
                            </a-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>