<template>
  <div class="paris">
    <ScrollupSection />
 <CookieConsent />
    <div class="all-area">
      <HeaderSection />
      <HeroSection />
      <PromoSection />
      <TrainingSection />
      <InsdiSection />
      <AnalyticsSection />
      <AutomationSection />
      <!--BrandingSection /-->
      <!--AboutSection />
      <WorkSection />

      <ScreenshotSection />
      <PricingSection />
      <ReviewSection />
      <TeamSection /-->
      <NewsletterSection />
      <!--DownloadSection /-->
      <!--BlogSection /-->
      <AboutIP/>
      <!--ContactSection /-->
      <FooterSection />
      
    </div>
       
  </div>
</template>

<script>
import ScrollupSection from '../components/Scrollup/Scrollup'
import HeaderSection from '../components/Header/Header_IP'
import HeroSection from '../components/Hero/HeroFive'
// import BrandingSection from '../components/Branding/Branding'
import PromoSection from '../components/Promo/PromoOne'
//import AboutSection from '../components/About/About'
//import WorkSection from '../components/Work/Work'
import TrainingSection from '../components/Features/Features'
import InsdiSection from '../components/Features/insdi'
import AnalyticsSection from '../components/Features/analytics'
import AutomationSection from '../components/Features/automation'
/*import ScreenshotSection from '../components/Screenshots/Screenshots'
import PricingSection from '../components/Pricing/Pricing'
import ReviewSection from '../components/Reviews/Reviews'
import TeamSection from '../components/Team/Team'*/
import NewsletterSection from '../components/Newsletter/Newsletter' 
//import DownloadSection from '../components/Download/Download'
import AboutIP from '../components/AboutUs/AboutIP'
//import BlogSection from '../components/Blogs/Blog/Blog'
//import ContactSection from '../components/Contact/Contact'
import FooterSection from '../components/Footer/Footer_IP'
import CookieConsent from 'vue-cookieconsent-component'

export default {
  name: 'ThemeFive',
  components: {
    ScrollupSection,
    HeaderSection,
    HeroSection,
    PromoSection,
   // BrandingSection,
  //  AboutSection,
  //  WorkSection,
    TrainingSection,
    InsdiSection,
    AnalyticsSection,
    AutomationSection,
  /*  ScreenshotSection,
    PricingSection,
    ReviewSection,
    TeamSection,*/
    NewsletterSection, 
    AboutIP,
  //  DownloadSection,
 //   BlogSection,
  //  ContactSection,
    FooterSection,
    CookieConsent
  }
}
</script>

<style>

.cookie-consent {
  position: fixed;
  z-index: 9999;
  width: 100%;
  padding: 1rem 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  bottom:0%;

  background: #222222 ;
}
  .cookie-consent-message,
  .cookie-consent-compliance {
    margin: .5rem 1rem;
  }

  .cookie-consent-compliance {
    cursor: pointer;
    color: #fff;
    padding: .5rem 2rem ;
    background: #8f9091;
background: rgba(0, 0, 0, 0) linear-gradient(-47deg, #8731E8 0%, #4528DC 100%) repeat scroll 0 0;
border: 2px solid currentColor;
  }

</style>