<template>
    <section id="home" class="section welcome-area bg-inherit d-flex align-items-center overlay-primary jarallax h-100vh pt-0" data-jarallax-video="mp4:../../assets/video/New video3.mp4">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-10 col-lg-8">
                    <div class="welcome-intro text-center">
                        <h1 class="text-white">Insurance Portal</h1><br>
                        <h4 class="text-white">Short Term Insurance and Data Specialists</h4>
                        <!--p class="text-white mt-4">Need to serve insurance training content to brokers? Have an unprofitable portfolio?  Want to digitise procuring data from clients? Need to understand your business better through analytics and dashboards? Automate regular admin tasks? or even scrape webdata to save time? </p-->
                        <h5 class="text-white mt-4 mb-5"><i> What gets measured, gets managed </i></h5>
                        <!--div class="input-group">
                            <input type="email" class="form-control" placeholder="Enter Your Email" aria-label="Recipient's username">
                            <div class="input-group-append">
                                <button class="btn btn-bordered-white" type="submit">Submit</button>
                            </div>
                        </div-->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>