<template>
    <section id="benifits" class="section benifits-area ptb_100">
        <div class="container">
            <div class="row">
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_1.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Product Training</h3>
                            <p>We host insurance product training for a number of insurers and UMAs.</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_4.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Client Data</h3>
                            <p>Secure your client's data when engaging with them using our easy to use configurator.</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_2.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Consulting</h3>
                            <p>We are Short Term Insurance Specialists in pricing, reinsurance and reserving.</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_3.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Integration</h3>
                            <p>Our gurus can automate tasks, from finance reconcilliations to reporting warehouses.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>