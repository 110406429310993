<template>
    <section id="training" class="section features-area ptb_100">
        <div class="shapes-container">
            <div class="shape bg-shape"></div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 col-lg-6">
                    <div class="features-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-4">
                            <span class="text-uppercase d-none d-sm-block">Training</span>
                            <h2 class="text-capitalize">Product Training from leading insurers</h2>
                        </div>
                        <p class="my-3">Brokers don't want to juggle multiple systems to complete product training. Product providers want to maximise visibility and completion rates of their product training courses.  Let us manage your training and provide detailed analytics for you to identify key weaknesses in product understanding.  </p>
                        <!-- Counter List -->
                        <div class="counter-list">
                            <ul>
                                <li>
                                    <!-- Single Counter -->
                                    <div class="single-counter px-4 py-3 text-center">
                                        <!-- Counter Icon -->
                                        <div class="counter-icon">
                                            <i class="icofont-safety"></i>
                                        </div>
                                        <!-- Counter Text -->
                                        <div >
                                            <span class="counterne d-inline-block mt-3 mb-2">200</span>+
                                            <h5>Brokers</h5><br>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <!-- Single Counter -->
                                    <div class="single-counter px-4 py-3 text-center">
                                        <!-- Counter Icon -->
                                        <div class="counter-icon">
                                            <i class="icofont-university"></i>
                                        </div>
                                        <!-- Counter Text -->
                                        <div >
                                            <span class="counterne d-inline-block mt-3 mb-2">10</span>+
                                            <h5>Product <br>Providers</h5>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <!-- Single Counter -->
                                    <div class="single-counter px-4 py-3 text-center">
                                        <!-- Counter Icon -->
                                        <div class="counter-icon">
                                            <i class="icofont-graduate"></i>
                                        </div>
                                        <!-- Counter Text -->
                                        <div >
                                            <span class="counterne d-inline-block mt-3 mb-2">30</span>+
                                            <h5>Courses <br> &nbsp; </h5>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <!-- Single Counter -->
                                    <div class="single-counter px-4 py-3 text-center">
                                        <!-- Counter Icon -->
                                        <div class="counter-icon">
                                            <i class="icofont-certificate-alt-2"></i>
                                        </div>
                                        <!-- Counter Text -->
                                        <div>
                                            <span class="counterne d-inline-block mt-3 mb-2">2500</span>+
                                            <h5>Accreditations <br>&nbsp;</h5>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                    <!-- Featured Items -->
                    <div class="featured-items">
                        <ul>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-3">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3">
                                        <img src="assets/img/icon_4.svg" alt="">
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Single Login</h3>
                                        <p>A single platform to complete product training from all participating product providers.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-3">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3">
                                        <img src="assets/img/icon_1.svg" alt="">
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Access</h3>
                                        <p>Users retain access to their certificates, even if they move to non-participating brokers.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-3">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3">
                                        <img src="assets/img/icon_3.svg" alt="">
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Immediate</h3>
                                        <p>Brokers manage their own users, allowing immediate access for new employees</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-3">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3">
                                        <img src="assets/img/icon_2.svg" alt="">
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Simple</h3>
                                        <p>Product providers don't have to manage systems and user management.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>


.featured-items ul li:nth-child(1) {
    background-color: rgba(36, 198, 88, 0.692);
}

.featured-items ul li:nth-child(2) {
    background-color: rgba(255, 157, 69, 0.692);
}

.featured-items ul li:nth-child(3) {
    background-color: rgba(0, 207, 242, 0.692);
}

.featured-items ul li:nth-child(4) {
    background-color: rgba(255, 69, 196, 0.692);
}

.counterne {
    font-size: 24px;
    font-weight: 600;
    color: #444;
}
</style>