<template>
    <section id="insdi" class="section about-app-area ptb_100">
        <!--div class="shapes-container">
            <div class="shape-1"></div>
        </div-->
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- About Thumb -->
                    <div class="about-thumb text-center d-none d-lg-block">
                        <img src="assets/img/insuranceForms.jpg" alt="">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- About Text -->
                    <div class="about-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-4">
                            <span class="text-uppercase d-none d-sm-block">Digitise</span>
                            <h2 class="text-capitalize">Secure, mobile ready client engagement</h2>
                        </div>
                        <p class="d-none d-sm-block my-3">Most companies use email or develop their own online capability to obtain client data.  But let's face it, email is unsecure, PDFs are tiresome and IT development is complicated and expensive.</p>
                        <p class="my-3">Configure secure, mobile friendly, user validated forms to enhance your client engagement such as <b>claim notifications, new business onboarding, risk questionaire &amp;  survey or asset registers</b>. 
                        <br> Quick setup with no coding experience necessary.</p>
                        <!-- Store Buttons -->
                        <div class="button-group store-buttons">
                            <a href="https://www.insdi.com" target="_blank" class="btn btn-bordered">
                                <i class="icofont icofont-diamond dsp-tc"></i>
                                <p class="dsp-tc">SHOW ME MORE
                                    <br>  <span>at insdi.com</span></p>
                            </a>
                            <a href="mailto:info@insuranceportal.co.za?subject=insdi demo account&body=Please give me a free demo account" class="btn btn-bordered">
                                <i class="icofont icofont-royal dsp-tc"></i>
                                <p class="dsp-tc">DEMO ACCOUNT
                                    <br> <span>Try for free</span></p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>