<template>
    <section id="analytics" class="section about-app-area ptb_100">
        <div class="shapes-container">
            <div class="shape-1"></div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- About Text -->
                    <div class="about-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-4">
                            <span class="text-uppercase d-none d-sm-block">Analytics</span>
                            <h2 class="text-capitalize">Data Modelling and reporting</h2>
                        </div>
                        <p class="d-none d-sm-block my-3">There is so much data being captured every day, but is your business using it effectively?  What valuable lessons are right in front of you, if only you knew how to look?</p>
                        <p class="my-3">We have a wealth of experience in <b>pricing and segmentation models, reporting dashboards, reserving, reinsurance and business processes</b>.</p>
                        <!-- Store Buttons -->
                        <div class="button-group store-buttons">
                            <a href="mailto:info@insuranceportal.co.za?subject=Analytics" class="btn btn-bordered">
                                <i class="icofont icofont-calendar dsp-tc"></i>
                                <p class="dsp-tc">NEED HELP?    
                                    <br> <span>Contact us</span></p>
                            </a>
                            <!--a href="#" class="btn btn-bordered">
                                <i class="icofont icofont-brand-apple dsp-tc"></i>
                                <p class="dsp-tc">AVAILABLE ON
                                    <br> <span>Apple Store</span></p>
                            </a-->
                        </div>
                    </div>
                </div>
               <div class="col-12 col-lg-6">
                    <!-- About Thumb -->
                    <div class="about-thumb text-center d-none d-lg-block">
                        <img src="assets/img/Analytics.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>