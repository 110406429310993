<template>
    <header class="section header-area">
        <div id="appo-header" class="main-header-area nav-white">
            <div class="container-fluid pr-3">
                <nav class="navbar navbar-expand-md navbar-light">
                    <!-- Logo -->
                    <a class="navbar-brand" href="/">
                        <img class="logo" src="assets/img/IPLogo_white.png" alt="">
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <!-- Appo Menu -->
                    <div class="collapse navbar-collapse" id="appo-menu">
                        <!-- Header Items -->
                        <ul class="navbar-nav header-items ml-auto">
                            <li class="nav-item align-text-bottom">
                                <a class="nav-link scroll" href="#training">Training</a>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link scroll" href="#insdi">Client data</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#analytics">Analytics</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#Automation">Automation</a>
                            </li>
                        </ul>
                        <div class="ml-3">
                            <a href="https://training.insuranceportal.co.za" target="_blank" class="btn btn-bordered" >
                                <!--i class="icofont icofont-graduate dsp-tc"></i-->
                                <a class="dsp-tc">Training login</a>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  
}
</script>

<style>

</style>