<template>
    <footer class="section footer-area footer-bg">
        <!-- Footer Top -->
        <div class="footer-top ptb_50">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Logo -->
                            <a class="navbar-brand" href="#">
                                <img class="logo" src="assets/img/IPLogo_white.png" alt="">
                            </a>
                            <p class="text-white-50 mt-2 mb-3">Insurance Portal</p>
                            <!-- Social Icons -->
                            <!--div class="social-icons d-flex">
                                <a class="facebook" href="#">
                                    <i class="fab fa-facebook-f"></i>
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a class="twitter" href="#">
                                    <i class="fab fa-twitter"></i>
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a class="google-plus" href="#">
                                    <i class="fab fa-google-plus-g"></i>
                                    <i class="fab fa-google-plus-g"></i>
                                </a>
                                <a class="vine" href="#">
                                    <i class="fab fa-vine"></i>
                                    <i class="fab fa-vine"></i>
                                </a>
                            </div-->
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4 offset-lg-1">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-white mb-2">Address</h3>
                            <ul>
                                <li>Unit 6265, Erinvale Estate, <br>Somerset West, 7130</li>
                                <!--li class="py-2"><a class="text-white-50" href="#">Home</a></li>
                                <li class="py-2"><a class="text-white-50" href="/Termsofuse">Privacy Policy</a></li>
                                <li class="py-2"><a class="text-white-50" href="https://sacoronavirus.co.za/">COVID notice</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Services</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Blog</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Contact</a></li-->
                            </ul>
                        </div>
                    </div>
                    <!--div class="col-12 col-sm-6 col-lg-3">
                        
                        <div class="footer-items">
                        
                            <h3 class="footer-title text-white mb-2">Product Help</h3>
                            <ul>
                                <li class="py-2"><a class="text-white-50" href="#">FAQ</a></li>
                                <li class="py-2"><a class="text-white-50" href="/Termsofuse">Privacy Policy</a></li>
                                < li class="py-2"><a class="text-white-50" href="#">Support</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Terms &amp; Conditions</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Contact</a></li>
                            </ul>
                        </div>
                    </div-->
                    <div class="col-12 col-sm-6 col-lg-3 offset-lg-1">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <h3 class="footer-title text-white mb-2">Useful links</h3>
                            <ul>
                                  <li class="py-2"><a class="text-white-50" href="/#/Termsofuse">Privacy Policy</a></li>
                                <li class="py-2"><a class="text-white-50" href="https://sacoronavirus.co.za/" target="_blank">COVID notice</a></li>
                               
                                <!--li class="py-2"><a class="text-white-50" href="#">For Windows</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">For Linux</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">For IOS</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">For Android</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">For Mac</a></li-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left text-white-50">&copy; Copyright 2019 - {{ new Date().getFullYear() }} | All rights reserved.</div>
                            <!-- Copyright Right -->
                            <!--div class="copyright-right text-white-50">Made with <i class="icofont-heart-alt"></i> By <a class="text-white-50" href="#">Theme Land</a></div-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    
</template>

<script>

export default {

}
</script>

<style>

</style>